import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useOptionalPartner } from '@/hooks/api/usePartner';
import { useUser } from '@/hooks/store';
import { logAmplitudeEvent } from '@/utils/analytics/amplitude';
import { logCalmEvent } from '@/utils/analytics/calm';
import { logSegmentEvent } from '@/utils/analytics/segment';
import setupEventProps from '@/utils/analytics/setupEventProps';
import { calmLogger } from '@/utils/calmLogger';

import { usePaymentInfo } from '../api/usePaymentInfo';
import { EventProps } from './types';

// Allow any since (as of now) we're just awaiting to make sure the event
// finished logging.
export type AnalyticsReturnType = void;

export function useAnalytics(): {
	logEvent: (eventName: string, eventProps?: EventProps) => AnalyticsReturnType;
} {
	const { user } = useUser();
	const { partnerId } = useParams() as { partnerId?: string };
	const { data: _partner } = useOptionalPartner(partnerId) ?? {
		data: undefined,
	};
	const { data: paymentInfo } = usePaymentInfo();

	const logEvent = useCallback(
		(eventName: string, _eventProps?: EventProps): void => {
			const partner = _partner ?? (partnerId ? { id: partnerId } : undefined);
			new Promise<void>((resolve, reject) => {
				const eventProps = setupEventProps(
					{
						...(paymentInfo?.type ? { payment_method_type: paymentInfo?.type } : {}),
						...(_eventProps || {}),
					},
					user,
					partner,
				);
				const logProps = {
					eventName,
					eventProps,
				};

				// Log all partner events here
				// In the future we can use parallel to execute events and then resolve async
				Promise.all([logCalmEvent(logProps), logAmplitudeEvent(logProps), logSegmentEvent(logProps)])
					.then(() => resolve())
					.catch(reject);

				if (process.env.CALM_ENV !== 'prod') {
					calmLogger.info(`Log event: ${eventName}`, eventProps ?? {});
				}
			}).catch(err => calmLogger.error('Error in logEvent', {}, err));
		},
		[_partner, partnerId, user, paymentInfo],
	);

	return { logEvent };
}
