import { AxiosPromise } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isPxResponse, useRecaptcha } from '@calm-web/px-client';

import { useAnalyticsWithPartner } from '@/hooks/analytics/useAnalyticsWithPartner';
import { useUser } from '@/hooks/store';
import { setRecaptchaVisible } from '@/store/actions';
import { JSONObject } from '@/types/json';
import apiRequest, { ApiRequestArgs } from '@/utils/apiRequest';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReturnShape = any;

export type SendApiRequest = <T = ReturnShape>(o: ApiRequestArgs) => AxiosPromise<T>;

// TODO: handle an unauthorized req here and revoke any user credentials (on a 401)
export function useApi(): SendApiRequest {
	const { partnerId } = useParams() as { partnerId?: string };
	const { user } = useUser();
	const { logEvent } = useAnalyticsWithPartner(partnerId ? { id: partnerId } : undefined);
	const dispatch = useDispatch();
	const accessToken = user?.accessToken;

	const logEventWrapper = useCallback(
		({ eventName, eventProps }: { eventName: string; eventProps?: JSONObject }) =>
			logEvent(eventName, eventProps),
		[logEvent],
	);

	const dispatchRecaptchaVisible = (value: boolean): void => {
		dispatch(setRecaptchaVisible(value));
	};

	const showRecaptcha = useRecaptcha(logEventWrapper, process.env.APP_API_URL, dispatchRecaptchaVisible);

	const makeApiRequest: SendApiRequest = useCallback(
		async o => {
			const customHeaders = {
				Authorization: accessToken || '',
				...(o?.customHeaders || {}),
			};
			const requestParamsWithAccessToken = { ...o, customHeaders };
			try {
				const request = await apiRequest(requestParamsWithAccessToken);
				return request;
			} catch (err) {
				if (isPxResponse(err) && showRecaptcha) {
					logEvent('Perimeter X : Error : Triggered', {
						method: requestParamsWithAccessToken.method || '',
						url: requestParamsWithAccessToken.endpoint,
					});
					await showRecaptcha(err?.data);
					return makeApiRequest(o);
				}
				throw err;
			}
		},
		[accessToken, showRecaptcha, logEvent],
	);

	return makeApiRequest;
}
