import { useCallback } from 'react';

import { useUser } from '@/hooks/store';
import { Partner } from '@/types/store/reducers';
import { logAmplitudeEvent } from '@/utils/analytics/amplitude';
import { logCalmEvent } from '@/utils/analytics/calm';
import { logSegmentEvent } from '@/utils/analytics/segment';
import setupEventProps from '@/utils/analytics/setupEventProps';
import { calmLogger } from '@/utils/calmLogger';

import { EventProps } from './types';

export type AnalyticsReturnType = void;

export function useAnalyticsWithPartner(partner?: Partial<Partner>): {
	logEvent: (eventName: string, eventProps?: EventProps) => AnalyticsReturnType;
} {
	const { user } = useUser();

	const logEvent = useCallback(
		(eventName: string, _eventProps?: EventProps): void => {
			new Promise<void>((resolve, reject) => {
				const eventProps = setupEventProps(_eventProps || {}, user, partner);
				const logProps = {
					eventName,
					eventProps,
				};

				// Log all partner events here
				// In the future we can use parallel to execute events and then resolve async
				Promise.all([logCalmEvent(logProps), logAmplitudeEvent(logProps), logSegmentEvent(logProps)])
					.then(() => resolve())
					.catch(reject);

				if (process.env.CALM_ENV !== 'prod') {
					calmLogger.info(`Log event: ${eventName}`, eventProps ?? {});
				}
			}).catch(err => calmLogger.error('Error in logEvent', {}, err));
		},
		[partner, user],
	);

	return { logEvent };
}
